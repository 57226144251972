
import { Component, Vue } from 'vue-property-decorator';
import api from '@/services/api';
import Auth from '@/services/auth';
import EventBus from '@/services/event-bus';

@Component
export default class Login extends Vue {
  private email = '';
  private password = '';
  private error = '';

  created() {
    if (this.$route.query.logout === '1') {
      Auth.logout();
    } else if (this.isLoggedIn) {
      this.gotoHomePage();
    }
  }

  get isLoggedIn() {
    return this.$store.state.loggedin;
  }

  async login() {
    try {
      const token = await api.login(this.email, this.password);
      Auth.login(token);
      EventBus.$emit('user-login');
      this.gotoHomePage();
    } catch (e) {
      console.error(e);
      this.error = 'Login failed';
    }
  }

  async resetPassword() {
    this.$alert('error', 'Not Implemented');
  }

  gotoHomePage() {
    this.$router.push({ name: 'customer' });
  }

  get valid() {
    return this.email !== '' && this.password !== '';
  }
}
