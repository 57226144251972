import EventBus from '@/services/event-bus';
import { Alert } from '@/models/alert';

const Alert = {
  install(Vue: any) {
    const newVue = Vue;
    newVue.prototype.$alert = (type: Alert['type'], text: string, duration?: number) => {
      const alert: Alert = { type, text, id: Math.random().toString(36).substr(2, 9), duration: duration || 3000 };
      EventBus.$emit('alert', alert);
    };
  },
};

export default Alert;
