
import { Component, Vue } from 'vue-property-decorator';
import EventBus from '@/services/event-bus';
import Navigation from '@/components/Navigation.vue';
import Alert from '@/components/Alert.vue';
import Api from './services/api';

@Component({
  components: {
    Alert,
    Navigation,
  },
})
export default class App extends Vue {
  private drawer = null;

  created(): void {
    EventBus.$on('user-logout', () => {
      this.$router.push('/login?logout=1');
    });
    this.getCurrentUser();
    EventBus.$on('user-login', () => {
      this.getCurrentUser();
    });
  }

  async getCurrentUser() {
    if (this.isLoggedIn) {
      const user = await Api.getUser(this.$store.state.auth.user_id);
      this.$store.commit('user', user);
    }
  }

  get isLoggedIn() {
    return this.$store.state.loggedin;
  }

  get version() {
    return this.$store.state?.auth?.version || '?';
  }
}
