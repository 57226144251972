import store from '@/store';

const getToken = () => {
  const { token } = localStorage;
  return token === '' || token === undefined ? null : token;
};

const parseJwt = (token: string): any => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const payload = atob(base64)
    .split('')
    .map((c) => {
      const a = `00${c.charCodeAt(0).toString(16)}`.slice(-2);
      return `%${a}`;
    })
    .join('');
  return JSON.parse(decodeURIComponent(payload));
};

const loggedin = getToken() !== null;
store.commit('loggedin', loggedin);
if (loggedin) {
  store.commit('auth', parseJwt(getToken()).sub);
}

class Auth {
  static getToken() {
    return getToken();
  }

  static login(token: string) {
    localStorage.token = token;
    store.commit('loggedin', true);
    store.commit('auth', parseJwt(token).sub);
  }

  static logout() {
    store.commit('loggedin', false);
    store.commit('auth', null);
    localStorage.removeItem('token');
  }
}

export default Auth;
