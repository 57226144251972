import axios, { AxiosResponse } from 'axios';
import EventBus from './event-bus';

const API_URL = process.env.VUE_APP_API_URL || '/api/v1';

const api = axios.create({
  baseURL: API_URL,
  headers: { common: { Accept: 'application/json' } },
});

// Include token in API requests
api.interceptors.request.use((config) => {
  const myConfig = config;
  if (localStorage.token !== null) {
    myConfig.headers.Authorization = `Bearer ${localStorage.token}`;
  }
  return myConfig;
});

// Logout on auth error
api.interceptors.response.use(
  (response) => response,
  // // Handle refresh tokens
  // if (response.headers.Authorization) {
  //   const token = response.headers.Authorization.split(' ')[1];
  //   localStorage.token = token;
  // }
  // return response;
  (error) => {
    // If auth error, logout
    if (error.response && error.response.status === 401) {
      EventBus.$emit('user-logout');
    }
    return Promise.reject(error.response);
  },
);

class Api {
  static readonly URL = API_URL;
  static getErrorMsg(prefix: string, error: Error | AxiosResponse) {
    const resp = error as AxiosResponse;
    if (resp && resp.data && resp.data.errors) {
      const errorMsg = JSON.stringify(resp.data.errors);
      return `${prefix} - ${errorMsg}`;
    }
    return prefix;
  }

  static async login(email: string, password: string) {
    const { data } = await api.post('/login', { email, password });
    return data.token;
  }

  static async listCustomer(params: object) {
    const { data } = await api.get('/customers', { params });
    return data;
  }

  static async getCustomer(id: number) {
    const { data } = await api.get(`/customers/${id}`);
    return data;
  }

  static async createCustomer(params: object) {
    const { data } = await api.post('/customers', params);
    return data;
  }

  static async updateCustomer(id: number, params: object) {
    const { data } = await api.patch(`/customers/${id}`, params);
    return data;
  }

  static async listCustomerDb(params: object) {
    const { data } = await api.get('/customer-dbs', { params });
    return data;
  }

  static async getCustomerDb(id: number) {
    const { data } = await api.get(`/customer-dbs/${id}`);
    return data;
  }

  static async getCustomerDbStatus(id: number) {
    const { data } = await api.get(`/customer-dbs/${id}/status`);
    return data;
  }

  static async getCustomerDbLocationStatus(id: number) {
    const { data } = await api.get(`/customer-dbs/${id}/location-status`);
    return data;
  }

  static async getCustomerDbHeartbeat(id: number) {
    const { data } = await api.get(`/customer-dbs/${id}/heartbeat`);
    return data;
  }

  static async createCustomerDb(params: object) {
    const { data } = await api.post('/customer-dbs', params);
    return data;
  }

  static async updateCustomerDb(id: number, params: object) {
    const { data } = await api.patch(`/customer-dbs/${id}`, params);
    return data;
  }

  static async getCustomerDbOptionConfig() {
    const { data } = await api.get('/customer-dbs/option-config');
    return data;
  }

  static async listCustomerDbCrontab(customerDbId: number) {
    const { data } = await api.get(`/customer-dbs/${customerDbId}/crontabs`);
    return data;
  }

  static async createCustomerDbCrontab(customerDbId: number, params: object) {
    const { data } = await api.post(`/customer-dbs/${customerDbId}/crontabs`, params);
    return data;
  }

  static async updateCustomerDbCrontab(customerDbId: number, id: number, params: object) {
    const { data } = await api.patch(`/customer-dbs/${customerDbId}/crontabs/${id}`, params);
    return data;
  }

  static async deleteCustomerDbCrontab(customerDbId: number, id: number) {
    const { data } = await api.delete(`/customer-dbs/${customerDbId}/crontabs/${id}`);
    return data;
  }

  static async createCustomerDbTask(customerDbId: number, params: object) {
    const resp = await api.post(`/customer-dbs/${customerDbId}/tasks`, params);
    if (!resp) {
      throw resp;
    }
    return resp.data;
  }

  static async fetchMember(customerDbId: number, remoteMemberId: string) {
    const resp = await api.post(`/customer-dbs/${customerDbId}/fetch-member/${remoteMemberId}`);
    if (!resp) {
      throw resp;
    }
    return resp.data;
  }

  static async deleteMember(customerId: number, memberUuid: string, block: boolean) {
    const resp = await api.delete(`/customers/${customerId}/members/${memberUuid}`, { data: { block } });
    if (!resp) {
      throw resp;
    }
    return resp.data;
  }

  static async processCustomerDbDataFile(customerDbId: number, params: object) {
    const resp = await api.post(`/customer-dbs/${customerDbId}/data-file`, params);
    if (!resp) {
      throw resp;
    }
    return resp.data;
  }

  static async createMemSystemWebhooks(customerDbId: number) {
    const resp = await api.post(`/customer-dbs/${customerDbId}/webhooks`);
    if (!resp) {
      throw resp;
    }
    return resp.data;
  }

  static async deleteMemSystemWebhooks(customerDbId: number) {
    const resp = await api.delete(`/customer-dbs/${customerDbId}/webhooks`);
    if (!resp) {
      throw resp;
    }
    return resp.data;
  }

  static async listLocation(customerId: number, params: object) {
    const { data } = await api.get(`/customers/${customerId}/locations`, { params });
    return data;
  }

  static async createLocation(customerId: number, params: object) {
    const { data } = await api.post(`/customers/${customerId}/locations`, params);
    return data;
  }

  static async updateLocation(customerId: number, id: number, params: object) {
    const { data } = await api.patch(`/customers/${customerId}/locations/${id}`, params);
    return data;
  }

  static async listMember(customerId: number, params: object) {
    const { data } = await api.get(`/customers/${customerId}/members`, { params });
    return data;
  }

  static async listMemberEvent(customerId: number, params: object) {
    const { data } = await api.get(`/customers/${customerId}/member-events`, { params });
    return data;
  }

  static async listUser(params: object) {
    const { data } = await api.get('/users', { params });
    return data;
  }

  static async getUser(userId: number) {
    const { data } = await api.get(`/users/${userId}`);
    return data;
  }

  static async createUser(params: object) {
    const { data } = await api.post('/users', params);
    return data;
  }

  static async updateUser(id: number, params: object) {
    const { data } = await api.patch(`/users/${id}`, params);
    return data;
  }

  static async changePassword(id: number, password: string) {
    const { data } = await api.patch(`/users/${id}/password`, { password });
    return data;
  }

  static async createUserAuth() {
    const { data } = await api.post('/user-auths');
    return data;
  }

  static async updateUserAuth(id: number, params: object) {
    const { data } = await api.patch(`/user-auths/${id}`, params);
    return data;
  }

  static async deleteUserAuth(id: number) {
    const { data } = await api.delete(`/user-auths/${id}`);
    return data;
  }

  static async listUserWebhook(userId: number, params: object) {
    const { data } = await api.get(`/users/${userId}/webhooks`, { params });
    return data;
  }

  static async createUserWebhook(userId: number, params: object) {
    const { data } = await api.post(`/users/${userId}/webhooks`, params);
    return data;
  }

  static async updateUserWebhook(userId: number, id: number, params: object) {
    const { data } = await api.patch(`/users/${userId}/webhooks/${id}`, params);
    return data;
  }

  static async deleteUserWebhook(userId: number, id: number) {
    const { data } = await api.delete(`/users/${userId}/webhooks/${id}`);
    return data;
  }

  static async sendToUserWebhook(userId: number, userWebhookId: number, params: object) {
    const { data } = await api.post(`/users/${userId}/webhooks/${userWebhookId}/send`, params);
    return data;
  }
}

export default Api;
