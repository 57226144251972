class Constants {
  static readonly CUSTOMER_DB_TYPE_API = 1;
  static readonly CUSTOMER_DB_TYPE_SOAP = 2;
  static readonly USER_TYPE_USER = 1;
  static readonly USER_TYPE_ADMIN = 10;

  static readonly SCOPE_MEMBER_CREATED = 'member-created';
  static readonly SCOPE_MEMBER_UPDATED = 'member-updated';
  static readonly SCOPE_MEMBER_PHOTO = 'member-photo';
  static readonly SCOPE_EVENT_ATTEND = 'event-attend';
  static readonly SCOPE_LOCATION = 'location';
  static readonly SCOPE_ADD_REMOTE_IDS = 'add-remote-ids';
  static readonly SCOPES = [
    Constants.SCOPE_MEMBER_CREATED,
    Constants.SCOPE_MEMBER_UPDATED,
    Constants.SCOPE_MEMBER_PHOTO,
    Constants.SCOPE_EVENT_ATTEND,
    Constants.SCOPE_ADD_REMOTE_IDS,
    Constants.SCOPE_LOCATION,
  ];
}

export default Constants;
