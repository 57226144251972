import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Login from '@/views/Login.vue';

Vue.use(VueRouter);

const isAllowed = (meta: RouteConfig['meta']) => {
  if (meta && meta.auth === false) {
    return true;
  }
  if (localStorage.token === '' || localStorage.token === undefined) {
    return false;
  }
  return true;
};

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { auth: false },
  },
  {
    path: '/user',
    name: 'user',
    component: () => import(/* webpackChunkName: "user" */ '@/views/User.vue'),
  },
  {
    path: '/customer',
    name: 'customer',
    component: () => import(/* webpackChunkName: "customer" */ '@/views/Customer.vue'),
  },
  {
    path: '/member/:customerId',
    name: 'member',
    component: () => import(/* webpackChunkName: "member" */ '@/views/Member.vue'),
  },
  {
    path: '/member-event/:customerId',
    name: 'member-event',
    component: () => import(/* webpackChunkName: "member-event" */ '@/views/MemberEvent.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue'),
  },
  {
    path: '/docs',
    name: 'docs',
    component: () => import(/* webpackChunkName: "docs" */ '@/views/Documentation.vue'),
  },
  {
    path: '*',
    meta: { auth: false },
    redirect: '/login',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (isAllowed(to.meta)) {
    next();
  } else {
    next('/login');
  }
});

export default router;
