
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Navigation extends Vue {
  get pages() {
    const adminPages = [{ name: 'user', icon: 'mdi-account-multiple', text: 'Users' }];
    const userPages = [
      { name: 'customer', icon: 'mdi-account-group', text: 'Customers' },
      { name: 'profile', icon: 'mdi-account', text: 'Profile' },
      { name: 'docs', icon: 'mdi-book-open-page-variant-outline', text: 'Documentation' },
    ];
    if (this.$store.getters.isLoggedInUserAdmin) {
      return adminPages.concat(userPages);
    }
    return userPages;
  }

  get isLoggedIn() {
    return this.$store.state.loggedin;
  }

  get loggedInAs() {
    return this.$store.state.user ? this.$store.state.user.email : '';
  }
}
