
import { Component, Vue } from 'vue-property-decorator';
import EventBus from '@/services/event-bus';
import { Alert as IAlert } from '@/models/alert';

@Component
export default class Alert extends Vue {
  private alerts = [] as IAlert[];

  created() {
    EventBus.$on('alert', (alert: IAlert) => {
      this.alerts.push(alert);
      setTimeout(() => {
        this.dismiss(alert.id);
      }, alert.duration);
    });
  }

  dismiss(id: string) {
    this.alerts = this.alerts.filter((alert) => alert.id !== id);
  }
}
