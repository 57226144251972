import Vue from 'vue';
import Vuex from 'vuex';
import { TAuth } from '@/models/auth';
import { TUser } from '@/models/user';
import Constants from '@/services/constants';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    auth: null as TAuth | null,
    user: null as TUser | null,
    loggedin: false,
  },
  getters: {
    loggedInUserId: (state) => state.auth && state.auth.user_id,
    isLoggedInUserAdmin: (state) => state.auth && state.auth.user_type === Constants.USER_TYPE_ADMIN,
  },
  mutations: {
    auth: (state, auth: TAuth) => {
      state.auth = auth;
    },
    user: (state, user: TUser) => {
      state.user = user;
    },
    loggedin: (state, loggedin: boolean) => {
      state.loggedin = loggedin;
    },
  },
  actions: {},
  modules: {},
});
