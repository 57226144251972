import Vue from 'vue';
import VuetifyConfirm from 'vuetify-confirm';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import Alert from './plugins/alert';

Vue.config.productionTip = false;
Vue.use(Alert);
Vue.use(VuetifyConfirm, { vuetify });

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
